body {
    color: black;
    font-family: Verdana;
}

.content-frame {
    display: grid;
    position: relative;
    grid-template-columns:auto 1fr;
    grid-template-rows:auto 1fr;
    grid-template-areas:"top top top"
                  "sidenav content props";
    min-width: 0;
    height: 100vh;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    align-content: stretch;
    display: -ms-grid;
    -ms-grid-columns: auto 1fr;
    -ms-grid-rows: auto auto 1fr
}

.side-nav-content {

}
.side-nav-copyright {
    height: 40px;
    position: fixed;
    bottom: 0;
}
.tile-properties {
    grid-area: props;
}
.containers-area {
    grid-area: content;
    background: #f5f5f6;
    overflow: auto;
    padding-left: 20px;
}
.main-container {
    overflow: auto;
    grid-template-rows:1fr auto;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-rows: 1fr auto;
    -ms-grid-columns: 1fr auto;
}

.drop-down-overlay {
    border-color: #005294;
    border-radius: 5px;
    border-style: solid;
    border-width: 3px;
    background-color: #b3d9ff;
    opacity: 95%;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    color: black;
    font-family: sans-serif;
    min-height: 30px;
}

.spacer {
    width: 100%;
    position: static;
    display: block;
}
.spacer-div {
    height: 60px;
    width: 100%;
    display: block;
    float: left;
    position: relative;
}
.tiles {
    width: 100%;
}

.tiles-area {
    width: 100%;
    overflow: auto;
    position: relative;
}
.container-header {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.container-header-button {
    border: 0px;
    background: #f5f5f6;
}
.container-header-text {
    padding-left: 10px;
    font-size: larger;
    margin: auto;
    text-align: right;
}
.user-details {
    padding-top: 15px;
    padding-right: 15px;
    color: white;
}
.side-nav {
    width: 150px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    align-self: stretch;
    background-image: linear-gradient(to bottom, white, whitesmoke);
}

.top-menu {
    grid-area: top;
    height: 70px;
    width: 100%;
    background-image: linear-gradient(to bottom, #005294, #237dc6);
    margin:0;
    text-align: right;
    padding-right: 15px;
}

.top-menu-logo {
    padding: 10px;
    height: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    vertical-align:middle;
    float: left;
    margin: 0 0 0 10px;
}
.tile {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    border-color: transparent;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    transition: box-shadow .1s linear;
    box-shadow: 0 2px 6px rgba(33, 33, 38, .2);
    float: left;
    padding: 15px;
    margin-top: 5px;
    margin-right: 15px;
    margin-bottom: 1.71429rem;
    font-size: 14px;
    color: #f1f1f1;
    height: 130px;
    width: 150px;
}
.tile-highlighted {
    border-color: #237dc6;
    opacity: 0.4;
}

.tile:hover {
    box-shadow: 0 4px 18px rgba(33, 33, 38, .2)
}

.tile-draggable {
    position: relative;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    height: 100%;
    float: left;
}

.app-logo--image {
    height: 60px;
    max-width: 100%;
    flex-shrink: 0;
}

.chiclet--action {
    position: absolute;
    top: 0;
    right: 0;
    padding: .42857rem .85714rem .85714rem;
    border: none;
    border-radius: 0 4px 0 4px;
    background: transparent
}
.chiclet--action:hover {
    background: #f1f1f1;
    color: black;
}

.chiclet--article {
    display: flex;
    flex-direction: column;
    flex: 1 1
}

.tile-a {
    display: flex;
    position: relative;
    padding-top: 10px;
    text-align: center;
    text-decoration: none;
    height: 100%;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom, #005294, #237dc6);
    background-color: #237dc6;
    color: #f1f1f1;
    font-weight: bold;
    border-radius: 15px;
}

.chiclet--app-title {
    margin: 0;
    max-width: 100%;
    color: #212126;
    font-size: .87055rem;
    font-weight: 400;
    overflow: hidden;
    max-height: 2.08932rem
}

.chiclet--footer {
    width: 150px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
